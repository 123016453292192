<template>
  <b-row class="hp-authentication-page">
    <left-item />

    <b-col cols="12" lg="6" class="py-sm-64 py-lg-0">
      <b-row align-v="center" align-h="center" class="h-100 mx-4 mx-sm-n32">
        <b-col
          cols="12"
          md="9"
          xl="7"
          class="col-xxxl-5 px-8 px-sm-0 pt-24 pb-48"
        >
          <dev-enviroment
            class="mx-auto"
            v-if="isDevEnviroment"
          ></dev-enviroment>
          <h1 class="mb-0 mb-sm-24">Iniciar sesión</h1>
          <p class="mt-sm-8 mt-sm-0 text-black-60">Bienvenido nuevamente.</p>

          <b-form class="mt-16 mt-sm-32 mb-8" @submit.prevent="formSubmit">
            <b-form-group
              label="Correo electrónico :"
              label-for="loginEmail"
              class="mb-16"
            >
              <b-form-input
                v-model="form.email"
                id="loginEmail"
                :class="{ 'is-invalid': $v.form.email.$error }"
                type="text"
              ></b-form-input>
              <div v-if="$v.form.email.$error" class="invalid-feedback">
                <span v-if="!$v.form.email.required"
                  >Correo electrónico es requerido.</span
                >
                <span v-if="!$v.form.email.email"
                  >Ingresa un correo electrónico valido.</span
                >
              </div>
            </b-form-group>

            <b-form-group
              label="Contraseña :"
              label-for="loginPassword"
              class="mb-16"
            >
              <b-form-input
                id="loginPassword"
                :class="{
                  'is-invalid': $v.form.password.$error,
                }"
                type="password"
                v-model="form.password"
              ></b-form-input>
              <div v-if="!$v.form.password.required" class="invalid-feedback">
                Contraseña es requerida.
              </div>
            </b-form-group>

            <b-row align-v="center" align-h="between" class="mb-16">
              <b-col class="hp-flex-none w-auto">
                <b-form-checkbox>Recordar</b-form-checkbox>
              </b-col>

              <b-col class="hp-flex-none w-auto">
                <b-link
                  class="hp-button text-black-80 hp-text-color-dark-40"
                  to="/pages/authentication/recover-password"
                >
                  ¿Olvidaste tu contraseña?
                </b-link>
              </b-col>
            </b-row>

            <b-button
              type="submit"
              :disabled="processing"
              variant="success-2"
              class="w-100"
            >
              <b-spinner
                v-if="processing"
                small
                type="grow"
                class="mr-8"
              ></b-spinner>
              Iniciar sesión
            </b-button>

            <div class="mt-3 pt-1 text-center">
              <p class="font-size-14 mb-2">O iniciar Sesión con:</p>
              <ul class="list-inline">
                <li class="list-inline-item" @click="loginWithGoogle">
                  <b-button
                    size="sm"
                    v-b-tooltip.hover
                    title="Iniciar sesión con Google"
                    variant="danger"
                    class="btn-ghost"
                  >
                    <i style="font-size: 18px" class="ri-google-fill"></i>
                  </b-button>
                </li>
              </ul>
            </div>
          </b-form>

          <footer-item />
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import LeftItem from "./LeftItem.vue";
import FooterItem from "./FooterItem.vue";
import { validationMixin } from "vuelidate";
const { required, minLength, email } = require("vuelidate/lib/validators");
import { mapGetters, mapActions } from "vuex";
import { PARAMS_LOGIN_GOOGLE, GOOGLE_AUTH_URL } from "@/constants/google";
import DevEnviroment from "../../../components/common/DevEnviroment.vue";

export default {
  components: {
    LeftItem,
    FooterItem,
    DevEnviroment,
  },
  data() {
    return {
      form: {
        email: "",
        password: "",
      },
    };
  },
  mixins: [validationMixin],
  validations: {
    form: {
      password: {
        required,
        minLength: minLength(4),
      },
      email: {
        required,
        email,
        minLength: minLength(4),
      },
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      processing: "auth/processing",
      loginError: "auth/loginError",
    }),
    isDevEnviroment() {
      return process.env.VUE_APP_ENVIROMENT_VARIABLE === "development";
    },
  },
  methods: {
    ...mapActions({ login: "auth/login" }),
    async formSubmit() {
      this.$v.$touch();
      this.$v.form.$touch();
      if (!this.$v.form.$anyError) {
        const response = await this.login({
          email: this.form.email,
          password: this.form.password,
        });
        if (response?.data?.error) {
          this.$bvToast.toast("Correo o Contraseña invalidos", {
            title: `Error`,
            variant: "danger",
            solid: false,
          });
        }
      }
    },
    loginWithGoogle() {
      const urlParams = new URLSearchParams(PARAMS_LOGIN_GOOGLE).toString();
      window.location = `${GOOGLE_AUTH_URL}?${urlParams}`;
    },
  },
  watch: {
    currentUser: {
      immediate: true,
      handler(val) {
        if (val && val.email) {
          setTimeout(() => {
            this.$router.push({ name: "my-checklists-pending" });
          }, 200);
        }
      },
    },
    loginError: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val != null) {
          console.log(val);
        }
      },
    },
  },
};
</script>
