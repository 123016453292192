export const SCOPE_GOOGLE = [
  'https://www.googleapis.com/auth/userinfo.email',
  'https://www.googleapis.com/auth/userinfo.profile'
].join(' ')

export const GOOGLE_AUTH_URL = 'https://accounts.google.com/o/oauth2/v2/auth'

export const PARAMS_LOGIN_GOOGLE = {
  response_type: 'code',
  client_id: process.env.VUE_APP_GOOGLE_ID,
  redirect_uri: `${process.env.VUE_APP_BASE_URL}/user/auth/login/google`,
  prompt: 'select_account',
  access_type: 'offline',
  scope: SCOPE_GOOGLE
}
